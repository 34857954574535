import { useState } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { useRecordContext, useGetOne } from 'react-admin'
import PaymentPanel from './SalvageBooking/PaymentPanel'
import TopSectionPanel from './SalvageBooking/TopSectionPanel'
import StatusPanel from './SalvageBooking/StatusPanel'
import CustomStatusPanel from './SalvageBooking/CustomStatusPanel'
import DetailsPanel from './SalvageBooking/DetailsPanel'
import FeesPanel from './SalvageBooking/FeesPanel'
import CustomSBMessage from './SalvageBooking/CustomSBMessage'
import PopupPanel from './SalvageBooking/PopupPanel'
import PropTypes from 'prop-types'
import ShoreCarsBooking from './SalvageBooking/ShoreCarsBooking'
import CancelledSalvageBooking from './SalvageBooking/Cancelled'
import CancelledInSQSalvageBooking from './SalvageBooking/CancelledInSQ'

const SalvageBooking = ({ userRoles }) => {
  const record = useRecordContext()
  const [noSBRecord, setNoSBRecord] = useState(false)
  const [popupPanelName, setPopupPanelName] = useState('')
  const [popupPanelProps, setPopupPanelProps] = useState({})

  const {
    data: sb,
    isLoading,
    error,
  } = useGetOne(
    'salvageBooking/leads',
    { id: record.originId },
    { retry: false }
  )

  if (!noSBRecord && isLoading) {
    return <CustomSBMessage message={<CircularProgress color="inherit" />} />
  }

  if (error) {
    if (error.status !== 404) {
      return (
        <CustomSBMessage
          message={
            'Something went wrong with Salvage Bookings, please try again later'
          }
        />
      )
    }
    // Prevent the loading icon replacing the content if record doesn't exist in SB
    if (!noSBRecord) setNoSBRecord(true)
  }

  if (record.leadContact.scam) {
    return (
      <CustomSBMessage
        message={
          'Lead is potentially scam, please remove this scam marker to see Salvage Bookings admin section'
        }
      />
    )
  }

  // Show cancelled view if cancelled
  if (
    ['ROLE_SHORE', 'ROLE_SALVAGE', 'ROLE_ADMIN'].filter((element) =>
      userRoles.includes(element)
    ).length &&
    record.leadContact.vehicle.vehicleSalvage &&
    (record.leadContact.vehicle.vehicleSalvage.assignedTo == 'cars' ||
      record.leadContact.vehicle.vehicleSalvage.assignedTo == 'salvage' ||
      (sb && sb.assigned_to_copart_at != null))
  ) {
    if (sb && sb.cancelled !== null) {
      return (
        <CancelledSalvageBooking
          sb={sb}
          popupPanelName={popupPanelName}
          setPopupPanelName={setPopupPanelName}
          popupPanelProps={popupPanelProps}
          setPopupPanelProps={setPopupPanelProps}
        />
      )
    }
    if (sb && record.status === 'Collection canceled') {
      return (
        <CancelledInSQSalvageBooking
          sb={sb}
          popupPanelName={popupPanelName}
          setPopupPanelName={setPopupPanelName}
          popupPanelProps={popupPanelProps}
        />
      )
    }
  }

  // Split between Shore Cars and BMS Salvage views
  if (
    ['ROLE_SHORE', 'ROLE_ADMIN', 'ROLE_SALVAGE'].filter((element) =>
      userRoles.includes(element)
    ).length &&
    record.leadContact.vehicle.vehicleSalvage &&
    record.leadContact.vehicle.vehicleSalvage.assignedTo == 'cars'
  ) {
    return <ShoreCarsBooking sb={sb} />
  } else if (
    ['ROLE_SALVAGE', 'ROLE_ADMIN'].filter((element) =>
      userRoles.includes(element)
    ).length &&
    record.leadContact.vehicle.vehicleSalvage &&
    (record.leadContact.vehicle.vehicleSalvage.assignedTo == 'salvage' ||
      (sb && sb.assigned_to_copart_at != null))
  ) {
    return (
      <Grid
        container
        spacing={3}
        style={{
          display: 'grid',
          gridColumn: '1 / span 6',
          gridRowStart: 4,
          gridRowEnd: 4,
          position: 'relative',
        }}
        sx={{
          marginTop: '0 !important',
          marginLeft: '0 !important',
          padding: '30px',
          width: '100%',
          minHeight: `${
            popupPanelName === ''
              ? '100%'
              : popupPanelName === 'sendEmail'
              ? '700px'
              : '500px'
          }`,
        }}
      >
        <PopupPanel
          sb={sb}
          popupPanelName={popupPanelName}
          setPopupPanelName={setPopupPanelName}
          popupPanelProps={popupPanelProps}
        />
        {/*Top Section*/}
        <Grid
          container
          spacing={3}
          sx={{
            borderBottom: '1px solid #8B4000',
            padding: '10px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <TopSectionPanel sb={sb} setPopupPanelName={setPopupPanelName} />
        </Grid>
        {/*End of top section*/}

        {/*Lead Details*/}
        <Grid
          container
          spacing={3}
          sx={{
            borderBottom: '1px solid #8B4000',
            padding: '0px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <DetailsPanel sb={sb} setPopupPanelName={setPopupPanelName} />
        </Grid>
        {/*End Lead Details*/}

        {/*Status details and reserve*/}
        <Grid
          container
          spacing={3}
          sx={{
            borderBottom: '1px solid #8B4000',
            padding: '20px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <StatusPanel sb={sb} />
        </Grid>
        {/*End Status details and reserve*/}

        {/*Custom Status section*/}
        <Grid
          container
          spacing={3}
          sx={{
            borderBottom: '1px solid #8B4000',
            padding: '20px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <CustomStatusPanel sb={sb} />
        </Grid>
        {/*End Custom Status section*/}

        {/*Payment section*/}
        {sb && sb.assigned_to_copart_at && sb.quarantined == null && (
          <Grid
            container
            spacing={3}
            sx={{
              borderBottom: '1px solid #8B4000',
              padding: '20px 0 !important',
              marginLeft: '0 !important',
              width: '100%',
              opacity: `${
                popupPanelName !== '' && popupPanelName !== 'sendEmail'
                  ? 0.05
                  : 1
              }`,
            }}
          >
            <PaymentPanel
              sb={sb}
              setPopupPanelName={setPopupPanelName}
              setPopupPanelProps={setPopupPanelProps}
            />
          </Grid>
        )}
        {/*End Payment section*/}

        {/*Fee section*/}
        <Grid
          container
          spacing={3}
          sx={{
            padding: '20px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <FeesPanel sb={sb} />
        </Grid>

        {/*End Fee section*/}
      </Grid>
    )
  }
}

SalvageBooking.propTypes = {
  userRoles: PropTypes.array,
}

export default SalvageBooking
