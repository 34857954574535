import { TextInput, SimpleForm, Create, BooleanInput } from 'react-admin'

const CreateTag = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput label="Tag Name" source="tagName" fullWidth required />
        <BooleanInput label="Should tag be hidden?" source="hidden" />
      </SimpleForm>
    </Create>
  )
}

export default CreateTag
