import { Edit, TextInput, SimpleForm, BooleanInput } from 'react-admin'

const EditTag = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="Tag Name" source="tagName" fullWidth required />
        <BooleanInput label="Should tag be hidden?" source="hidden" />
      </SimpleForm>
    </Edit>
  )
}

export default EditTag
