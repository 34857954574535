import { grey, lightGreen, pink, red } from '@mui/material/colors'
import { defaultTheme } from 'react-admin'
import { yellow } from './colors'

export const darkTheme = {
  ...defaultTheme,
  typography: {
    htmlFontSize: 14,
    fontSize: 10,
    fontFamily: [
      'SF Pro Text',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    RaTextInput: {
      variant: 'outlined',
    },
    RaSelectInput: {
      helperText: ' ',
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          borderRadius: '0',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 20px 8px',
          '&:last-child': {
            paddingBottom: '8px',
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          fontWeight: 500,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        helperText: ' ',
        size: 'small',
        color: 'primary',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          flex: '1',
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: grey[900],
          height: 'unset',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: '0.15rem',
          marginBottom: '0.7rem',
          paddingTop: '0',
          paddingBottom: '0',
          minHeight: '35px',
          fontWeight: 500,
        },
        notchedOutline: {
          borderWidth: '1.5px',
          textTransform: 'uppercase',
          color: yellow,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'none',
          '&.Mui-error': {
            display: 'block',
            marginTop: '-10px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 700,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#222',
          fontWeight: 700,
          borderRadius: 0,
          '&.add-contact-log-modal': {
            background: '#000000',
            border: '5px solid red',
            padding: '20px',
            position: 'relative',
            zIndex: '999999',
            animation: 'alert 1s ease 0s 1 normal forwards',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '4px',
        },
      },
      defaultProps: {
        sx: {
          textTransform: 'uppercase',
          color: yellow,
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'outlined',
      },

      styleOverrides: {
        root: {
          marginTop: '2px',
          fontWeight: 'bold',
          minHeight: '37px',
        },
        variants: [
          {
            props: { variant: 'dashboard-link' },
            style: {
              fontSize: '14px',
              textTransform: 'none',
              textDecoration: 'none',
              padding: '0',
              color: '#FFF',
              '> span': {
                color: yellow[500],
                marginLeft: '1rem',
              },
            },
          },
        ],
        contained: {
          border: '1px solid !important',
          minHeight: '37px',
        },
        outlined: {
          borderWidth: '1.5px !important',
          minHeight: '37px',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: yellow,
    secondary: pink,
    background: {
      default: '#A3A3A3',
      card: '#222',
    },
    good: {
      main: lightGreen[500],
      light: lightGreen[300],
      dark: lightGreen[700],
    },
    bad: {
      main: '#AA2929',
      light: red[300],
      dark: red[700],
    },
  },
}
